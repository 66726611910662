<template>
  <v-card>
    <v-card-title>
      <AddMappingModal ref="addModal" @formSubmitted="addMapping" />
      <v-spacer />
      <v-text-field
        v-model="search"
        class="shrink"
        append-icon="mdi-magnify"
        label="Wyszukaj"
        single-line
        hide-details
        @keyup.enter="fetchItems"
        @blur="fetchItems"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:item.name="{item}">
        {{ item.name }}
      </template>
      <template v-slot:item.category="{item}">
        {{ item.category.name }}
      </template>
      <template v-slot:item.actions="{on, item}">
        <EditMappingModal ref="editModal" @formSubmitted="updateMapping" :item="item" />
        <v-icon v-on="on" size="16" @click="removeMapping(item.id)" class="ml-2">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <Confirm ref="confirmModal" />
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import AddMappingModal from '@/components/Modals/AddMappingModal'

const EditMappingModal = () => import('@/components/Modals/EditMappingModal')
const Confirm = () => import('@/components/UI/Confirm')

const MappingRepository = RepositoryFactory.get('mapping')

export default {
  name: 'Mapping',
  components: {AddMappingModal, EditMappingModal, Confirm},
  mixins: [paginationMixin],
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        {text: 'Słowo kluczowe', value: 'name', width: '45%'},
        {text: 'Nazwa kategorii', value: 'category', width: '45%'},
        {text: 'Akcje', value: 'actions', width: '10%', sortable: false},
      ],
    }
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['getMappingList', 'showNotification']),
    async fetchItems() {
      const {search} = this
      this.$data.$_paginationMixin_fetchingItems = true
      const query = this.$_paginationMixin_createQuery({
        ...this.$data.$_paginationMixin_options,
        search,
      })
      this.$data.$_paginationMixin_totalCount = await this.getMappingList({
        ...query,
      })
      this.$data.$_paginationMixin_fetchingItems = false
    },
    async addMapping(form) {
      try {
        await MappingRepository.addMapping({
          categoryId: form.categoryId,
          keywords: [form.keyword],
        })
        this.$refs.addModal.close()
        await this.fetchItems()
        this.showNotification({
          message: 'NOTY_MAPPING_ADDED',
          type: 'success',
        })
      } catch (e) {
        this.showNotification({
          message: 'NOTY_MAPPING_ADDING_ERROR',
          type: 'error',
        })
      }
    },
    async updateMapping(form) {
      try {
        await MappingRepository.updateMapping(form)
        await this.fetchItems()
        this.showNotification({
          message: 'NOTY_MAPPING_UPDATED',
          type: 'success',
        })
      } catch (e) {
        this.showNotification({
          message: 'NOTY_MAPPING_UPDATE_ERROR',
          type: 'error',
        })
      }
    },
    async removeMapping(mappingId) {
      const res = await this.$refs.confirmModal.open({
        title: 'Usuwanie mapowania',
        message: 'Czy napewno chcesz usunąć mapowanie?',
      })
      if (res) {
        try {
          await MappingRepository.removeMapping({mappingId})
          await this.fetchItems()
          this.showNotification({
            message: 'NOTY_MAPPING_REMOVED',
            type: 'success',
          })
        } catch (e) {
          this.showNotification({
            message: 'NOTY_MAPPING_REMOVE_ERROR',
            type: 'error',
          })
        }
      }
    },
  },
  computed: {
    ...mapState({
      items: state => state.mapping.mappingList,
    }),
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
      }
    },
  },
}
</script>

<style></style>
