<template>
  <v-dialog :max-width="370" v-model="show">
    <template v-slot:activator="{on}">
      <v-btn color="primary" v-on="on" @click="open">
        Dodaj
      </v-btn>
    </template>
    <v-form :valid="valid" @submit.prevent="save" @keyup.enter="save" ref="form" lazy-validation>
      <v-card>
        <v-card-title class="py-6">
          <h6 class="text-h6 mx-auto">Nowe mapowanie</h6>
        </v-card-title>
        <v-card-text>
          <v-text-field
            :rules="$rules.required"
            label="Słowo kluczowe"
            dense
            outlined
            v-model="form.keyword"
          />
          <AllCategoriesAutocomplete :rules="$rules.required" v-model="form.categoryId" />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn elevation="0" @click="close">Anuluj</v-btn>
          <v-btn color="primary" type="submit" :loading="pending">Dodaj</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AllCategoriesAutocomplete from '@/components/UI/Autocompletes/AllCategoriesAutocomplete'

export default {
  name: 'AddMappingModal',
  components: {AllCategoriesAutocomplete},
  props: {
    pending: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      form: {
        keyword: '',
        categoryId: null,
      },
      show: false,
      valid: true,
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
      this.$refs.form.reset()
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('formSubmitted', this.form)
      }
    },
  },
}
</script>

<style scoped></style>
