const paginationMixin = {
  data() {
    return {
      $_paginationMixin_options: {},
      options: {},
      $_paginationMixin_loading: false,
      $_paginationMixin_rowsPerPageOptions: [25, 40, 50, 100],
      rowsPerPageOptions: [20, 40, 50, 100],
      itemsPerPage: 20,
      $_paginationMixin_itemsPerPage: 20,
      totalCount: 0,
      $_paginationMixin_totalCount: 0,
      fetchingItems: false,
      $_paginationMixin_fetchingItems: false,
    }
  },
  methods: {
    $_paginationMixin_createQuery(pagination, options = {}) {
      const {page, itemsPerPage, search, sortBy, sortDesc} = pagination
      const OrderBy = sortBy.length > 0 ? `${sortBy[0]}+${sortDesc[0] ? 'desc' : 'asc'}` : null
      const query = {
        PageNumber: page,
        PageSize: itemsPerPage,
        SearchQuery: search,
        OrderBy,
        ...options,
      }

      return query
    },
  },
}

export default paginationMixin
