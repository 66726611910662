<template>
  <v-autocomplete
    dense
    outlined
    autocomplete="off"
    label="Nazwa kategorii"
    cache-items
    item-value="id"
    item-text="name"
    v-bind="$attrs"
    :items="categories"
    :search-input.sync="search"
    :loading="isFetchingOptions"
    :value="value"
    @input="onInputHandler"
  ></v-autocomplete>
</template>

<script>
import RepositoryFactory from '@/repositories/RepositoryFactory'

const CategoryRepository = RepositoryFactory.get('categories')
export default {
  name: 'AllCategoriesAutocomplete',
  props: {
    categoryName: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      search: '',
      categories: [],
      isFetchingOptions: false,
    }
  },
  created() {
    this.fetchItems()
    if (this.categoryName) {
      this.search += this.categoryName
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.fetchItems()
      }
    },
  },
  methods: {
    async fetchItems() {
      this.isFetchingOptions = true
      const {data} = await CategoryRepository.getCategoryList({
        SearchQuery: this.search,
        PageNumber: 1,
        PageSize: 50,
      })
      this.categories = data.categories
      this.isFetchingOptions = false
    },
    onInputHandler(id) {
      this.$emit('input', id)
    },
  },
}
</script>

<style scoped></style>
